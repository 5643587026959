import React from "react";
import { Page } from "components/Page";
import { Link } from "gatsby";
import { Tile } from "components/Tile";

const EventsPage = () => {
  return (
    <Page>
      <div className="container mx-auto mt-16"></div>
    </Page>
  );
};

export default EventsPage;
